/* 

CASE STUDY PAGE 

*/

#portfolio-list .active {
    pointer-events: none;
}

.case-study-content {
    box-shadow: 0 0px 120px 120px var(--background-darker);
    background-color: var(--background-darker);
}

.case-page {
    padding: 0 40px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 150;
    flex-direction: column;
    box-sizing: border-box;
    color: var(--text);
}

.case-contents div {
    padding:0 7.352941%;
    margin-bottom: 8rem;
}

.case-details {
    display: flex;
    margin-bottom: 6rem;
    margin-top: -4vh;
}


.meta-data div {
    width: 100%;
    margin-bottom: 2rem; 
    font-family: var(--regular);
}

.meta-data {
    font-size: 0.8rem;
}

.meta-data h4 {
    font-weight: 700;
    margin: 0;
}

.pull-quote h4 {
    font-family: var(--headertype);
    font-weight: normal;
    color: #ECDBC2;
    font-size: calc((0.09*100vw) + 20.5px);
    line-height: .98;
}

.project-desc {
    margin-top: -0.6em;
}

.project-desc * {
    max-width: 800px;
}

.project-desc p {
    font-family: var(--regular);
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.44;
    margin-bottom: .8rem;
    opacity: 0.9;
    margin-top: 0;
}

.asset-full {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20rem;
}

.case-page img, .case-page video {
    border-radius: 18px;
}

.asset-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}


@media (max-width: 1024px) {

    .meta-data {
        margin-left: 0;
    }
    
    .case-details .project-desc {
        width: 77.9411764%;
        flex-direction: column;
    }

    .project-desc div {
        width: 100%;
    }

    .brief {
        margin-bottom: 2rem;
    }


    .case-page img, .case-page video {
        border-radius: 16px;
    }

}

@media (max-width: 800px) {
    .meta-data {
        width: 100%;
        margin: 0 -28px;
    }

    .meta-data div {
        width: 33%;
    }

    .case-page {
        margin-top: clamp(600px, 86vh, 2000px);
    }

    .case-details {
        flex-direction: column;
    }
    
    .case-details div {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.4rem;
        align-content: flex-start;
    }
    
    .meta-data div {
        display: block;
        flex-basis: fit-content;
        margin-bottom: .8rem;
        margin: 0 28px .8rem 28px;
        font-family: var(--regular);
        font-size: 0.76rem;
    }
}

@media (max-width: 680px) {

    .case-page {
        margin-top: 0;
    }   

    .case-details .project-desc {
        width: 100%;
    }

    .case-details {
        margin-bottom: 4rem;
    }

    .case-page {
        padding: 0 20px;
    }

    .case-contents div {
        padding: 0;
        margin-bottom: 12vw;
    }

    .case-contents .width-4 {
        width: 36%;
    }

    .case-contents .width-12 {
        width: 100%;
    }

}

@media (max-width: 450px) {
    .meta-data div {
        width: 100%;
    }
      
    .project-desc p {
        font-size: 1rem;
    }

    .case-contents .width-3,
    .case-contents .width-4,
    .case-contents .width-9,
    .case-contents .width-10,
    .case-contents .width-11 {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 2rem;
    }

    .case-contents .asset-right {
        justify-content: center;
    }

}
