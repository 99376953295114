@import url("https://use.typekit.net/peb1piv.css");

:root {
  --background:#181E23;
  --download:#1B2227;
  --background-darker:#080a0c;
  --background-80:rgba(24, 30, 35, .8);
  --text:#CFEAF9;
  --text-half:#99AFBB;
  --text-40:rgba(207, 234, 249, .4);
  --text-20:rgba(207, 234, 249, .2);
  --green:#3CCC76;
  --highlight:#FF7878;
  --regular: aktiv-grotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --headertype: moret, 'Georgia', "Lucida Serif", Lucida, serif ;
}

/*
  Widths & columns
*/

.gutter-left{
  margin-left: 2.94117%;
}

.gutter-right{
  margin-right: 2.94117%;
}

.col-1{
  margin-left: 7.35294117%;
}

.col-2{
  margin-left: 11.764705%;
}

.col-3{
  margin-left: 19.1176470%;
}

.col-4{
  margin-left: 26.4705882%;
}

.col-5{
  margin-left: 33.82352941%;
}

.col-6{
  margin-left: 41.1764705%;
}

.col-7{
  margin-left: 48.5294117%;
}

.col-8{
  margin-left: 55.88235294%;
}

.col-9{
  margin-left: 63.235294117%; 
}

.col-10{
  margin-left: 70.5882352%;
}

.col-11{
  margin-left: 77.9411764%;
}

.col-12{
  margin-left: 85.29411764%;
}

.width-2{
  width: 11.764705%;
}

.width-3{
  width: 19.1176470%;
}

.width-4{
  width: 26.470588%;
}

.width-5{
  width: 33.8235294%;
}

.width-6{
  width: 41.176470%;
}

.width-7{
  width: 48.529411%;
}

.width-8{
  width: 55.88235%;
}

.width-9{
  width: 63.23529%;
}

.width-10{
  width: 70.5882353%;
}

.width-11{
  width: 77.9411764%;
}

.width-12{
  width: 85.2941176%;
}

.width-14{
  width: 100%;
}


/* 

Landing page 

*/

html, body {
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-family: var(--regular);
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  background-color: var(--background);
  width: 100%;
  height: 100%;
}

.bold {
  font-weight: 700;
}

.align-right {
  text-align: right;
}

a {
  color: var(--text);
} 

a:visited {
  color: var(--text);
} 

#root, .home {
  padding: 0;
  margin: 0;
  background-color: var(--background);
}

.landing {
  box-sizing: border-box;
  background-color: var(--background);
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  margin: 0;
  left: 50%;
  top: 0;
  color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-wrap {
  width: 50vw;
  padding: 0 80px;
  position: relative;
  box-sizing: border-box;
}

.landing-content {
  padding-left: 8.705357%;
}

.landing h1 {
  font-family: var(--headertype);
  font-weight: normal;
  font-size: min(3.7rem, 8vh, 5vw);
  line-height: 1.18;
  padding: 0; 
  margin: 0 0 40px 0;
  text-shadow: 0 0 20px rgba(255, 249, 111, 0.22);
}

.landing h3 {
  font-family: var(--regular);
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.4;
  padding: 0;
  margin: 0 0 8px 0;
}

.landing a {
  text-decoration: underline;
  color: var(--text);
}

.landing a:visited {
  color: var(--text);
}

.landing a:hover {
  color: var(--highlight);
}

.landing h4 {
  font-family: var(--regular);
  color: var(--text-half);
  font-weight: normal;
  line-height: 1.2;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status {
  display: flex;
}

.status::before {
  content: '';
  background-color: var(--green);
  display: block;
  width: 4px;
  border-radius: 2px;
  margin-right: 24px;
  box-shadow: 0 0 14px 0 rgba(206, 255, 125, 0.3);
}

.landing span {
  background-color: var(--text-half);
  display: inline-block;
  width: 4px; 
  height: 4px;
  border-radius: 2px;
  margin: 0 8px 0;
}

/* 

Navigation 

*/

.top-nav {
  z-index: 2000;
  width: 100vw;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 40px;
  box-sizing: border-box;
}

.top-nav {
  position: fixed;
}

.top-nav a {
  line-height: 1.2;
  font-size: 0.8rem;
  text-decoration: none;
  color: var(--text);
  font-weight: 700;
}

.top-nav a:visited {
  color: var(--text);
}

.top-nav a:hover {
  color: var(--highlight);
  cursor: pointer;
}

.top-nav .active, .home .homelink {
  opacity: 0.1;
}

.top-nav .active:hover, .home .homelink:hover {
  color: var(--text);
  cursor: default;
}


/* 

Overlay for Page transition 

*/


#loadOverlay {
  width: 100%;
  height: 100%;
  background-color: var(--background-darker);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 400ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message {
  position: relative;
  width: 100%;
}

.loading-message h1 {
  font-family: var(--headertype);
  font-weight: 800;
  letter-spacing: 0.03em;
  font-size: 2.6rem;
  line-height: 1.18;
  margin: 0;
  -webkit-text-stroke: .5px var(--text);
  text-stroke: .5px var(--text);
  color: transparent;
  position: absolute;
  opacity: 0;
  text-align: center;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  transform: translate(0, -50%);
}

h1#top-h1 {
  color: var(--text);
  -webkit-text-stroke: 0;
  opacity: 0;
  clip-path: polygon(0% 100%, 100% 180%, 100% 170%, 0% 180%);
  text-stroke: 0;
}

/* 

Portfolio 

*/

.work-nav {
  position: fixed;
  left: 0;
  height: max(640px, 100vh);
}

.work-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#port-wrap {
  width: 100%;
  height: 100%;
  position: relative;
}

#portfolio-list {
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.desktop-hero {
  display: block;
}

.mobile-hero {
  display: none;
}

.carousel-visual {
  position: relative;
  height: 100%;
  border-radius: 20px;
}

.work-nav li {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.work-nav a {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.work-nav a img, .work-nav a video {
  left: calc(160px);
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  width: 100vh;
}

#portfolio-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--background-darker);
}

#portfolio-background li {
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: visible;
}

.bg-wrapper {
  height: calc(100vh + 160px);
  opacity: 0;
  position: absolute;
  top: 50%;
  left: -80px;
  transform: translateY(-50%);
  transition: all 600ms ease-in-out;
}

.bg-wrapper.active-bg {
  opacity: .8;
}

.titleOverlay {
  position: absolute;
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.titleOverlay:hover {
  transition: opacity 400ms ease-in-out 800ms;
}

.work-nav a:hover .titleOverlay {
  /* opacity: 1; */
  opacity: 0;
}

.case-study .work-nav a:hover .titleOverlay {
  opacity: 0;
}

.titleOverlay::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.8;
  background-color: var(--background-darker);
  mix-blend-mode: color;
}

.titleOverlay h2 {
  margin: 0;
  padding: 0;
  z-index: 3;
  color: var(--highlight);
  font-family: var(--headertype);
  font-size: 6rem;
  line-height: .9;
  font-weight: 300;
  font-style: italic;
}

.headerDetails {
 position: absolute;
 width: 100vw;
 height: 100vh;
 padding: 10vh 40px;
}

.headerDetails h2 {
  position: relative;
  margin: 0 0 0 7.35294117%;
  font-family: var(--headertype);
  padding: 0;
  color: var(--text);
  font-size: 280px;
  font-weight: 200;
  line-height: .8;
  text-indent: -0.3em;
  opacity: 0;
}

#pagination {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  margin-left: 40px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 400ms;
}

#pagination button {
  width: 16px;
  height: 16px;
  margin: 8px 0;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--text);
  opacity: .6;
  padding: 0;
  transition: all 600ms ease;
}

#pagination button::after {
  content: "";
  background-color: var(--text);
  width: 6px;
  height: 6px;
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 600ms ease;
  opacity: 0;
}

#pagination button:hover {
  opacity: 1;
  transition: all 300ms ease;
  /* background-color: var(--highlight); */
  border: 1px solid var(--highlight);
  cursor: pointer;
}

#pagination button.active-ui {
  opacity: 1;
}

#pagination button.active-ui:hover {
  opacity: 1;
  border: 1px solid var(--text);
  cursor: default;
}


#pagination button.active-ui::after {
  opacity: 1;
}

#pagination button.active-ui:hover::after {
  opacity: 1;
}

.case-study #pagination {
  opacity: 0;
  pointer-events: none;
}

/* 

ABOUT PAGE 

*/

.about-content {
  position: absolute;
  box-sizing: border-box;
  width: 50vw;
  padding: 12vw 80px 40px 80px;
  background-color: var(--background);
  margin: 0;
  right: 0;
  opacity: 1;
}

.about-content h3 {
  font-weight: normal;
}

.about-content > div:not(:last-child) {
  margin-bottom: 200px;
}

.bio a:first-child {
  margin-right: 2em;
}

.bio a {
  font-family: var(--regular);
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 8px 0;
}

.bio  a:hover {
  color: var(--highlight);
}

.bio p, .about-content h3 {
  font-family: var(--headertype);
  font-size: 2rem;
}

.logo-block {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.logo-block img {
  width: 30%;
  flex-basis: 160px;
  transform: scale(.7);
  opacity: 0.8;
  margin-bottom: 2rem;
}

.downloads a {
  transition: all 200ms ease-in-out;
}

.download-holder {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 60px;
  margin: -8px;
}

.downloads a {
  background-color: var(--download);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  min-width: 240px;
  padding: 24px;
  flex-grow: 1;
  margin: 8px;
}

.downloads a:hover {
  color: var(--background);
  background-color: var(--highlight);
}

a:visited {
  color: var(--text);
}

.downloads h4 {
  font-family: var(--headertype);
  font-weight: normal;
  font-size: 2.6rem;
  margin-bottom: 16px;
  line-height: 1.2;
}

.downloads p {
  font-family: var(--wide);
  font-size: 0.8rem;
  margin: 0 0 24px 0;
}

.downloads span {
  font-weight: 700;
}

table {
  font-size: 0.8rem;
  text-align: left;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 24px 16px 24px 0;
  vertical-align: top;
}

tbody th:nth-child(1) {
  width: 42%;
}

tbody td:nth-child(2) {
  width: 33%;
}

tbody td:nth-child(3) {
  width: 25%;
  font-size: 0.64rem;
  text-align: right;
  padding: 24px 0 24px 16px;
  color: var(--text-half);
}

tr:not(:first-child) {
  border-top: 1px solid var(--text-20);
}

tr.sub {
  border-top: none;
}

.sub td {
  padding: 0 16px 24px 0;
}

.sub td:nth-child(2) {
  text-align: right;
  padding: 0 0 24px 16px;
  color: var(--text-half);
  font-size: 0.64rem;
}

.about-footer {
  font-size: 0.64rem;
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid var(--text-20);
  flex-wrap: wrap;
}

.about-footer p {
  margin: 0;
  line-height: 1.6;
}


/* 

FOOTER

*/

footer {
  display: flex;
  font-size: 0.72rem;
  padding: 32px 40px;
  background-color: var(--background-darker);
  flex-flow: wrap;
}



footer a {
  font-weight: 700;
  margin: 0 0 8px 0;
  text-decoration: none;
}

.case-links > *,  .site-links > * , .out-links > * {
  margin: 0 0 10px 0;
}

.out-links > * {
  display: block;
}

.bus-details {
  padding-left: 2.94117%;
  border-left: 1px solid var(--text-20);
}

footer a:hover {
  cursor: pointer;
  color: var(--highlight);
}

.bus-details p {
 margin: 0 0 2px 0;
}
.half-text {
  color: var(--text-40);
}

/* 

---------- TRANSITIONS ----------

*/


/* Home  ->  About */

.about .work-nav {
  /* 1 Remove the portfolio */
  transition: opacity 400ms ease-in;
  opacity: 0;
}

.about .landing {
  /* 2 Animation of the landing content sliding to the left for about page */
  transition: left 1600ms ease-in-out 400ms, opacity 400ms linear 200ms;
  left: 0;
  opacity: 1;
}

.about-content {
  /* 3 Slide and fade the about content in */
  transition: left 1200ms ease-out, opacity 600ms ease-out 600ms;
}

.about-content.fade-enter {
  opacity: 0;
  /* right: -30%; */
}

.about-content.fade-active, .about-content.fade-enter-done {
  opacity: 1;
  right: 0;
}

/* About  ->  Home */

.about-content.fade-exit-active {
   /* 1 Animation of the about content */
  transition: right 800ms ease-in, opacity 600ms ease-in;
  opacity: 0;
  /* right: -30%; */
}

.home .landing {
  /* 2 Animation of the landing content sliding back to the right */
  transition: left 1600ms ease-in-out 200ms;
}

.work-nav {
  /* 3 Fade the work nav back in */
  transition: opacity 600ms ease-out 1800ms;
  /* left: -100%; */
  opacity: 1;
}

/* Home  ->  Case study */

.case-study .landing {
  /* Animation of the landing content slidign off screen to the right */
  transition: right 2000ms ease-in 300ms, opacity 800ms ease-in 400ms;
  right: -50%;
  opacity: 0;
}

.case-study .work-nav {
  /* Ensure the case study header scrolls with the page content*/
  position: relative;
}

.case-study .work-nav a img, .case-study .work-nav a video {
  /* Animation of the case-study main image on the case study page */
  transition: all 1500ms ease-in-out 300ms;
}

.case-study #portfolio-background {
  position: absolute;
  overflow-x: hidden;
}

.case-study #portfolio-list {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.case-study .work-nav a img, .case-study .work-nav a video {
  left: calc((100vw - 80px));
  width: calc((100vw - 80px) * .68);
  height: auto;
  transform: translate3d(-100%, 0, 0);
}

.case-study .bg-wrapper {
  width: calc(100vw + 160px);
}

.case-study-content {
  /* Case study content enters */
  transition: all 800ms ease-out 2400ms;
  position: absolute;
  margin-top: 0;
  opacity: 1;
  width: 100%;
}

.case-study-content.fade-enter {
  /* Case study content starting starting state */
  margin-top: 60px;
  opacity: 0;
}

.case-study-content.fade-enter-active, .case-study-content.fade-enter-done  {
  /* Case study content final state */
  margin-top: 0;
  opacity: 1;
}


/* Slide and fade the title behind the main image  */

.home .headerDetails h2 {
  /* the transition here is animating back to home */
  transition: opacity 600ms ease-in, top 600ms ease-in 600ms;
  opacity: 0;
  top: 150px;
}

.case-study .headerDetails h2 {
  /* the transition here is animating in on the case study page */
  transition: all 600ms ease-out 1600ms;
  opacity: 1;
  top: 100px
}

/* Casestudy  ->  Home */

/* 

1. Keep the content stationary. 
2. Fade overlay
3. Scroll page to top
4. Homepage build

*/

.home-transition  #loadOverlay {
  /* Styles for the overlay when the page is transitioning to home */
  z-index: 4000;
  transition: opacity 800ms;
  background-color: var(--background-darker);
  opacity: 1;
}

.home-transition .loading-message {
  opacity: 0;
}

.home .case-study-content {
  background-color: none;
  opacity: .2;
}

.home .case-study-content::before {
  content: "";
  display: block;
  height: max(640px, 100vh);
  width: 100%;
}


/* Casestudy  ->  About */

/* 

1. Keep the content stationary. 
2. Fade and move out content
3. Scroll page to top
4. Slide in left section
5. Slide in right section

*/

/* 

---------- PAGE BUILDS ----------

*/


/* Page build 1 - class 'home-first' for initial site load and 'home-start' for subsequent */

.home-first #loadOverlay {
  /* Styles for the overlay when the page is transitioning to home */
  z-index: 4000;
  opacity: 1;
}


h1.top-load-message {
  opacity: 1;
  animation: clip-right 1500ms ease-out 1 forwards;
}


@keyframes clip-right {
  0% { 
    opacity: 1;
    clip-path: polygon(0% 100%, 100% 180%, 100% 170%, 0% 180%)
  }
  100% { 
    opacity: 1; 
    clip-path: polygon(0% -80%, 100% 0%, 100% 100%, 0% 100%)
  }
}

.home-first .loading-message h1 {
  opacity: 1;
}

@keyframes fade-in-out {
  0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}

.home-first .landing {
  transition: right 2000ms ease-in 300ms, opacity 800ms ease-in 400ms;
  width: 100%;
  right: 0;
  background-color: var(--background-darker);
}

.home-first .landing-wrap {
  opacity: 0;
}

/* Page build 2 - class 'home-start' */

.home-start .loading-message {
  transition: opacity 400ms ease;
  opacity: 0;
}

.home-start #loadOverlay {
  /* Styles for the overlay when the page is transitioning to home */
  z-index: 4000;
  opacity: 0;
  background-color: var(--background);
  transition: opacity 400ms ease 400ms, background-color 800ms;
}

.home-start .landing {
  width: 100%;
}

.home-start .landing-wrap {
  opacity: 0;
  clip-path: polygon(0% 0%, 100% 180%, 100% 170%, 0% 180%);
}

/* Page build 2 - class 'home-active' */

.home-active .landing {
  width: 50%;
  transition: width 800ms ease-in-out;
}

.home-active .landing-wrap {
  transition: opacity 800ms ease-out 800ms;
  opacity: 1;
}


/* 

---------- Media Queries ----------

*/

@media (max-width: 1240px) {
  .about-content {
    padding: 12vw 60px 40px 60px;
  }
}

@media (max-width: 1024px) {

  .landing h4, .landing h3 {
    font-size: 0.76rem;
  }

  .landing-wrap {
    padding: 20px 40px;
  }

  .about .landing {
    right: 100%;
    opacity: 0;
  }

  .about-content {
    width: 100vw;
    padding: 12vw 40px 40px 40px;
  } 

}

@media (max-width: 740px) {
  .top-nav {
    padding: 24px 20px;
  }

  .about .top-nav, .case-study .top-nav {
    backdrop-filter: blur(20px);
  }

  .landing-wrap {
    padding: 20px 20px;
  }
}


@media (max-width: 680px) {

  #root {
    min-height: 200vh;
  } 
  
  .home, .case-study {
    display: flex;
    flex-direction: column;
  }

  .loading-message h1 {
    font-size: 1.8rem;
    padding: 0 40px;
  }

  .landing, .landing-wrap {
    width: 100%;
  }

  .landing {
    position: relative;
    left: 0;
    min-height: fit-content;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .home-active .landing {
    width: 100vw;
  }

  .landing-wrap {
    padding: 60px 20px 20px 20px;
  }

  .landing-content {
    padding-left: 0;
  }
  
  .landing h1 {
    font-size: 2rem;
  }

  .status {
    flex-direction: column;
  }

  .status::before {
    width: 40px;
    height: 4px;
    margin-bottom: 18px;
  }

  .work-nav {
    position: absolute;
    min-height: 600px;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  .home .work-nav, .about .work-nav {
    position: fixed;
  }

  #port-wrap {
    overflow-x: scroll;
  }

  #portfolio-list {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  #portfolio-background {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  #portfolio-background li {
    width: 100%;
    height: 100%;
  }

  .desktop-hero {
    display: none;
  }

  .mobile-hero {
    display: block;
  }

  .bg-wrapper {
    height: calc(100% + 200px);
    width: auto;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bg-wrapper.active-bg {
    opacity: .6;
  }

  .work-nav a {
    align-items: center;
  }

  .work-nav a img, .work-nav a video {
    border-radius: 20px;
    width: 60vw;
    left: unset;
    right: unset;
    height: auto;
    position: absolute;
    transform: translate(0, 0);
    transition: width 0ms ease-in-out 400ms;
    z-index: 1;
  }

  .carousel-visual {
    border-radius: 8px;
  }

  #pagination {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 100%;
    left: 50%;
    padding-bottom: 16px;
    margin: 0 0 40px 0;
    transform: translate(-50%, -100%);
  }

  #pagination button {
    width: 12px;
    height: 12px;
    margin: 0 8px;
  }

  #portfolio-list li {
    width: 100vw;
    /* margin-right: -20vw; */
  }

  /* About page */

  .about .work-nav {
    transition: left 2400ms ease-in, opacity 400ms ease-in;
    opacity: 0;
  }

  .about .landing {
    transition: right 1600ms ease-in-out 400ms, opacity 800ms linear 400ms;
    right: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
  }

  .about-content > div:not(:last-child) {
    margin-bottom: 80px;
  }

  /* Case study page */

  .case-study .landing-content {
    opacity: 0;
  }

  .case-study .landing {
    height: 0;
    right: 0;
    min-height: 0px;
    opacity: 1;
  }

  .case-study .work-nav {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }

  .case-study #portfolio-list {
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .case-study #port-wrap {
    overflow-x: hidden;
  }

  .case-study #portfolio-list li {
    transition: width 1600ms ease-in-out;
    width: 100vw;
  }

  .case-study .bg-wrapper {
    width: auto;
  }

  .case-study .work-nav a img, .case-study .work-nav a video {
    width: 54vw;
    left: unset;
    right: 40px;
    position: absolute;
    transform: translate(0, 10%);
    transition: width 1200ms ease-in-out;
  }

  .headerDetails {
    padding: 0 20px;
    width: auto;
    left: 0;
  }

  .headerDetails h2 {
    font-size: 90px;
    margin: 0;
    text-indent: 0;
    top: 60px;
  }
  .case-study .headerDetails h2 {
    top: 80px;
  }

}

@media (max-width: 420px) {

  .about-content {
    padding: 120px 20px 40px 20px;
  }

  .about-footer div {
    flex-basis: 100%;
  }

  .about-footer .align-right {
    text-align: left;
  }

  .top-nav {
    padding: 16px 20px;
  }

  table {
    font-size: 0.7rem;
  }

  .bio p, .about-content h3 {
    font-size: 1.6rem;
  }

  .logo-block img {
    width: 50%;
  }

  .downloads h4 {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }

  .downloads p {
    font-size: 0.68rem;
    margin-bottom: 8px;
  }

  footer {
    padding: 32px 20px;
  }
  
  footer div {
    flex-basis: fit-content;
    margin-bottom: 24px;
    font-size: 0.64rem;
  }

  footer div.col-1 {
    margin-left: 0;
  }

  .bus-details {
    flex-basis: 100%;
    padding: 16px 0 0 0;
    font-size: 0.64rem;
    border-left: 0;
    border-top: 1px solid var(--text-20);
    margin-bottom: 0;
    margin-top: 32px;
  }

  .case-links > *, .site-links > *, .out-links > * {
    margin: 0 0 16px 0;
  }

}